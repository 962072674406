import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { useState, useEffect } from "react";
import ReportListToolbar from "../components/ReportListToolbar";
import orderService from "../services/orderService";
import AlertMessage from "../../../components/alert/alert";
import inboxOutline from "@iconify/icons-eva/inbox-outline";
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  CardContent,
  TableContainer,
  TablePagination,
  Container,
  InputAdornment,
  Box,
  OutlinedInput,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import searchFill from "@iconify/icons-eva/search-fill";
import OrdersListHead from "../components/OrdersListHead";
import SearchNotFound from "../../../components/SearchNotFound";
import Page from "../../../components/Page";
import Scrollbar from "../../../components/Scrollbar";
import PasswordModal from "../components/PasswordModal";
import financialService from "../../adm/financial/services/financialService";
import { styled } from "@mui/system";
const TABLE_HEAD = [
  { id: "created_at", label: "Data", alignRight: false },
  { id: "id_Order", label: "O.S", alignRight: false },
  { id: "description", label: "Descrição", alignRight: false },
  { id: "Tipo lente2", label: "Status", alignRight: false },
  { id: "Tipo lente", label: "Tipo lente", alignRight: false },
  { id: "amount", label: "Qtde (Pares)", alignRight: false },
  { id: "valueWithDiscount", label: "Valor", alignRight: false },
];
const StageNAME: any = {
  1: "BAIXA",

  2: "LENSOMETRO",

  3: "PERDA",

  4: "RETRABALHO",

  5: "VERIFICACAO VISUAL",

  6: "REPROVADA NO RECEBIMENTO",

  7: "CANCELADA",

  8: "CONTINUAR PROCESSO",

  9: "ENVIADA",
  CADASTRADA: "Cadastrada",
  RECEBIDA: "Recebida",
};
function nameStageCalculator(data: any) {
  if (data.name_Stage) {
    return StageNAME[data.name_Stage];
  }
  if (data.receivementDate_Order === null) {
    return "Recebida";
  }

  return "Cadastrada";
}

const SearchStyle = styled(OutlinedInput)(({ theme }: any) => ({
  height: 48,
  float: "left",
  borderRadius: "40px",
  backgroundColor: "#fff",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function Report() {
  const parse: any = localStorage.getItem("user");
  const user = JSON.parse(parse);

  const [page, setPage] = useState(0);
  const [data, setData]: any = useState([]);
  const [order, setOrder] = useState("asc");
  const [password, setPassword] = useState("");
  const [showError, setError] = useState(false);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [filterDate, setFilterDate] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [passwordModal, setPasswordModal] = useState(false);
  const [paginationData, setPaginationData]: any = useState({});
  const [refresh, setRefresh] = useState(false);
  const [dataSelect, setDataSelect]: any = useState("0");
  const [dataFilial, setDataFilial]: any = useState("0");
  const [dataFilter, setDataFilter]: any = useState([]);
  const [sumData, setSumData]: any = useState({});

  useEffect(() => {
    setOrder("asc");
    setOrderBy("name");
  }, []);

  useEffect(() => {
    if (password.length) {
      orderService
        .getReport(
          user.idCustomer?.toString(),
          user.prepaid,
          password,
          page,
          rowsPerPage,
          filterName,
          dataSelect
        )
        .then((data: any) => {
          setData(data.data?.result);
          setSumData(data.data?.sum);
          setPaginationData(data.data?.pagination);
          setPasswordModal(false);
        })
        .catch((error: any) => {
          setError(true);
          setPassword("");
          console.error(error);
        });
    } else setPasswordModal(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, refresh, dataSelect, password]);

  useEffect(() => {
    if (showError) setTimeout(() => setError(false), 2000);
  }, [showError]);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    financialService
      .getAll("0", "50", {
        startDate: undefined,
        endDate: undefined,
      })
      .then((data: any) => setDataFilter(data.data))
      .catch((error: any) => console.error(error));
  }, []);

  const handleChangeBilling = (event: any) => {
    setDataSelect(event.target.value);
  };

  return (
    <>
      <Page title="Relatório | Optools">
        <Container>
          <PasswordModal
            open={passwordModal}
            handler={() => setPasswordModal(false)}
            changePassword={(pass: string) => setPassword(pass)}
          />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h4" gutterBottom>
              Relatório
            </Typography>
          </Stack>
          <Stack
            direction={["column", "row"]}
            justifyContent="flex-start"
            py={2}
          >
            <SearchStyle
              sx={{
                width: ["100%", 256],
                mr: 2,
              }}
              type="text"
              value={filterName}
              placeholder="Pesquisar..."
              inputProps={{ style: { textTransform: "uppercase" } }}
              onChange={(element: any) => setFilterName(element.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <Box
                    component={Icon}
                    icon={searchFill}
                    sx={{ color: "text.disabled" }}
                  />
                </InputAdornment>
              }
            />
            <FormControl sx={{ pr: 2, minWidth: 380 }}>
              <InputLabel id="demo-select-small-label">
                Data de fechamento
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={dataSelect}
                label="Escolha o fechamento"
                onChange={handleChangeBilling}
              >
                <MenuItem value={"0"}>Ordens em aberto</MenuItem>
                {dataFilter.map((item: any, index: any) => (
                  <MenuItem value={item.id_Billing} key={index}>
                    Fechamento {item.id_Billing} -{" "}
                    {item.serverRegisterDate_Billing}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              sx={{
                height: "48px",
                borderRadius: "40px",

                background: "#004A78",

                marginBottom: ["24px", 0],
                "&:hover": {
                  backgroundColor: "#226C9A",
                },
              }}
              onClick={() => {
                setPage(0);
                setRefresh(!refresh);
              }}
              color="secondary"
              variant="contained"
            >
              Pesquisar
            </Button>
          </Stack>
          <Card>
            <CardContent sx={{ paddingTop: [0, "auto"] }}>
              <Scrollbar>
                <TableContainer>
                  <Table>
                    <OrdersListHead headLabel={TABLE_HEAD} />
                    <TableBody>
                      {data.length > 0 ? (
                        data.map((row: any, index: number) => {
                          const {
                            created_at,
                            lensesAmount_Order,
                            name_Lens,
                            valueWithDiscount,
                            value_ServiceOrder,
                            code_Order,
                            balance,
                            idDesign_Order,
                            name_Stage,
                          } = row;
                          if (created_at)
                            return (
                              <TableRow
                                hover
                                key={index}
                                tabIndex={-1}
                                role="checkbox"
                                selected={false}
                                aria-checked={false}
                              >
                                <TableCell component="th" scope="row">
                                  <Typography
                                    variant="subtitle2"
                                    noWrap
                                    sx={{
                                      color: "#004A78",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {created_at}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography
                                    variant="subtitle2"
                                    noWrap
                                    sx={{
                                      color: "#004A78",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {code_Order}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography
                                    variant="subtitle2"
                                    noWrap
                                    sx={{
                                      color: "#004A78",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {name_Lens}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography
                                    variant="subtitle2"
                                    noWrap
                                    sx={{
                                      color: "#004A78",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {nameStageCalculator(row)}
                                  </Typography>
                                </TableCell>

                                <TableCell align="left">
                                  <Typography
                                    variant="subtitle2"
                                    noWrap
                                    sx={{
                                      color: "#004A78",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {idDesign_Order === 3
                                      ? "Normal"
                                      : "Recortada"}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography
                                    variant="subtitle2"
                                    noWrap
                                    sx={{
                                      color: "#004A78",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {lensesAmount_Order / 2}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography
                                    variant="subtitle2"
                                    noWrap
                                    sx={{
                                      color: "#004A78",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {console.log(valueWithDiscount)}
                                    {valueWithDiscount !== "R$ 0,00"
                                      ? valueWithDiscount
                                      : value_ServiceOrder}
                                  </Typography>
                                </TableCell>

                                {user.prepaid ? (
                                  <TableCell align="left">
                                    <Typography
                                      variant="subtitle2"
                                      noWrap
                                      sx={{
                                        color: "#004A78",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {balance}
                                    </Typography>
                                  </TableCell>
                                ) : undefined}
                              </TableRow>
                            );
                          else return undefined;
                        })
                      ) : (
                        <>
                          <TableRow>
                            <TableCell
                              align="center"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              <Stack
                                width="100%"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Icon
                                  icon={inboxOutline}
                                  width="56px"
                                  color="#909090"
                                />
                                <Typography
                                  mt={1}
                                  variant="body1"
                                  noWrap
                                  sx={{ color: "#909090" }}
                                >
                                  Nenhum item encontrado...
                                </Typography>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        </>
                      )}

                      <TableRow sx={{ backgroundColor: "#004A78" }}>
                        <TableCell colSpan={7} sx={{ pr: 10 }}>
                          <Stack
                            flexDirection="row"
                            width="100%"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography
                              variant="body1"
                              noWrap
                              sx={{ color: "#FFF" }}
                            >
                              Valor Total
                            </Typography>
                            <Typography
                              variant="body1"
                              noWrap
                              sx={{ color: "#FFF", fontWeight: "bold" }}
                            >
                              {sumData.sumDiscount === "R$ 0,00"
                                ? sumData.sumTotal
                                : sumData.sumDiscount}
                            </Typography>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                    {/*                     {isUserNotFound && data.length ? (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : undefined} */}
                  </Table>
                </TableContainer>

                <TablePagination
                  page={page}
                  component="div"
                  count={paginationData.totalRecords}
                  rowsPerPage={rowsPerPage}
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={[10, 20, 50, 100, 200]}
                  labelRowsPerPage="Linhas por página:"
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelDisplayedRows={(rowsInfo) => {
                    const totalPages = Math.ceil(
                      rowsInfo.count / rowsPerPage
                    ).toString();
                    return (
                      <>
                        {`Página: ${page + 1}${`/${
                          totalPages === "0" ? "1" : totalPages
                        }`}`}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {`Total: ${rowsInfo.count}`}
                      </>
                    );
                  }}
                />
              </Scrollbar>
            </CardContent>
          </Card>
        </Container>
      </Page>
      {showError && <AlertMessage open={true} message="Senha incorreta!" />}
    </>
  );
}
