import { Navigate, useRoutes } from "react-router-dom";
import { useState, useEffect } from "react";
// layouts
import DashboardLayout from "./layouts/dashboard";

// Dashboard and Error Page
import NotFound from "./pages/Page404";
import DashboardApp from "./pages/DashboardApp";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";

// Auth
import Login from "./pages/auth/login/pages/Login";
import ResetPasswordForm from "./pages/auth/reset-password/pages/ResetPassword";
import RequestPassword from "./pages/auth/request-password/pages/RequestPassword";

// ADM Pages
import Tags from "./pages/adm/tags/pages/Tags";
import Clients from "./pages/adm/clients/pages/Clients";
import Sellers from "./pages/adm/clients/pages/Sellers";
import Products from "./pages/adm/products/pages/Products";
import NewClient from "./pages/adm/clients/pages/NewClient";
import NewSeller from "./pages/adm/clients/pages/NewSeller";
import Financial from "./pages/adm/financial/pages/Financial";

// OPERATIONAL Pages
import OperationalOrders from "./pages/operational/pages/Orders";
import OperationalNewOrder from "./pages/operational/pages/NewOrder";

// CUSTOMER Pages
import Orders from "./pages/customer/pages/Orders";
import Profile from "./pages/customer/pages/Profile";
import NewOrder from "./pages/customer/pages/NewOrder";

//===============================================================================
import http from "./services/http-common";
import Administration from "./pages/adm/administrative/pages/Administration";
import Groups from "./pages/adm/group/pages/Groups";
import NewGroup from "./pages/adm/group/pages/NewGroup";
import Report from "./pages/customer/pages/Report";

import jwt_decode from "jwt-decode";
import Maintenance from "./pages/Maintenance";
import ReportFinancial from "./pages/adm/financial/pages/ReportFinancial";
import ReportAdmin from "./pages/adm/financial/pages/ReportAdmin";
import ReportPrePaid from "./pages/customer/pages/ReportPrePaid";

export default function Router() {
  const token = localStorage.getItem("token");
  const parse: any = localStorage.getItem("user");
  const user = JSON.parse(parse);
  const maintenance = false;
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    setIsLoggedIn(!!token);
    if (token) {
      const decoded: any = jwt_decode(token);
      if (decoded.exp >= Math.floor(Date.now() / 1000)) {
        setIsLoggedIn(true);
        http.defaults.headers.common.Authorization = `Bearer ${token}`;
      } else {
        setIsLoggedIn(false);
        localStorage.clear();
        delete http.defaults.headers.common["Authorization"];
      }
      http.defaults.headers.common["Content-Type"] = "application/json";
    }
  }, [token, isLoggedIn]);

  return useRoutes([
    {
      path: "/dashboard",
      element: maintenance ? (
        <Navigate to="/maintenance" />
      ) : isLoggedIn ? (
        <DashboardLayout />
      ) : (
        <Navigate to="/" />
      ),
      children: [
        {
          path: "/dashboard",
          element: <Navigate to="/dashboard/app" replace />,
        },
        { path: "app", element: <DashboardApp /> },
        { path: "clientes", element: <Clients /> },
        { path: "colaboradores", element: <Sellers /> },
        { path: "produtos", element: <Products /> },
        { path: "etiquetas", element: <Tags /> },
        { path: "clientes/cadastrar", element: <NewClient /> },
        { path: "colaboradores/cadastrar", element: <NewSeller /> },
        { path: "administracao", element: <Administration /> },
        { path: "financeiro", element: <Financial /> },
        //-------------- OPERACIONAL ------------------
        { path: "operacional", element: <OperationalOrders /> },
        { path: "buscar", element: <OperationalNewOrder /> },

        { path: "grupos", element: <Groups /> },
        { path: "grupos/cadastrar", element: <NewGroup /> },
        //-------------- CLIENTE ----------------------
        { path: "dados", element: <Profile /> },
        { path: "pedidos", element: <Orders /> },
        { path: "cadastrar", element: <Orders /> },
        { path: "pedidos/cadastrar", element: <NewOrder /> },
        {
          path: "relatorio",
          element: user?.prepaid ? <ReportPrePaid /> : <Report />,
        },

        { path: "relatorio-admin", element: <ReportAdmin /> },
        { path: "reports", element: <ReportFinancial /> },
      ],
    },
    {
      path: "/",
      element: maintenance ? (
        <Navigate to="/maintenance" />
      ) : !isLoggedIn ? (
        <LogoOnlyLayout />
      ) : (
        <Navigate to="/dashboard" />
      ),
      children: [
        { path: "/", element: <Navigate to="/entrar" /> },
        { path: "entrar", element: <Login /> },
        { path: "resetar-senha/:token", element: <ResetPasswordForm /> },
        { path: "solicitar-senha", element: <RequestPassword /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: maintenance ? (
        <Navigate to="/maintenance" />
      ) : (
        <Navigate to="/404" replace />
      ),
    },
    {
      path: "maintenance",
      element: maintenance ? <Maintenance /> : <Navigate to="/" replace />,
    },
  ]);
}
