/* eslint-disable camelcase */
import { Icon } from "@iconify/react";
import { useState, useEffect } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink } from "react-router-dom";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import inboxOutline from "@iconify/icons-eva/inbox-outline";
import QRCodeIcon from "@iconify/icons-ic/round-qr-code-scanner";
import { useNavigate } from "react-router-dom";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  Checkbox,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  FormControlLabel,
} from "@mui/material";
// components
import Page from "../../../../components/Page";
import ClientOptions from "../components/ClientOptions";
import Scrollbar from "../../../../components/Scrollbar";
import ClientsListHead from "../components/ClientsListHead";
import ClientsListToolbar from "../components/ClientsListToolbar";

import ClientModal from "../components/ClientModal";
import ReportModal from "../components/ReportModal";
import clientService from "../services/clientService";

export default function Clients() {
  const parse: any = localStorage.getItem("user");
  const user = JSON.parse(parse);
  let navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [data, setData]: any = useState([]);
  const [idClient, setIdClient] = useState(0);
  const [prePaid, setPrePaid] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [updateData, setUpdateData] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [notFirstQuery, setNotFirstQuery] = useState(false);
  const [queryStartEnd, setQueryStartEnd] = useState([
    "0",
    rowsPerPage.toString(),
  ]);

  const [params, setParams]: any = useState({
    value: "",
    prepaid: undefined,
    situation: undefined,
  });

  const [tableHead, setTableHead] = useState([
    { id: "name_Customer", label: "Cliente", alignRight: false },
    { id: "name_Company", label: "Unidade", alignRight: false },
    { id: "total_Customer", label: "Verificado", alignRight: false },
    { id: "contact", label: "Contato", alignRight: false },
    { id: "", label: "Editar" },
  ]);

  useEffect(() => {
    if (user.role !== "Vendedor")
      setTableHead([
        { id: "name_Customer", label: "Cliente", alignRight: false },
        { id: "initials_Customer", label: "Código", alignRight: false },
        { id: "lastDate", label: "Última data", alignRight: false },
        { id: "sellerName_Customer", label: "Vendedor", alignRight: false },
        { id: "name_Company", label: "Unidade", alignRight: false },
        { id: "total_Customer", label: "Verificado", alignRight: false },
        { id: "contact", label: "Contato", alignRight: false },
        { id: "qrcode", label: "Gerar Etiqueta", alignRight: false },
        { id: "prepaid_Customer", label: "Relatório", alignRight: false },
        { id: "", label: "Editar" },
      ]);
  }, [user.role]);

  useEffect(() => {
    clientService
      .getAll(queryStartEnd[0], queryStartEnd[1], params)
      .then((data: any) => setData(data.data))
      .catch((error: any) => console.error(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData, queryStartEnd, params.prepaid, params.situation]);

  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        notFirstQuery
          ? clientService
              .getAll(queryStartEnd[0], queryStartEnd[1], params)
              .then((data: any) => setData(data.data))
              .catch((error: any) => console.error(error))
          : setNotFirstQuery(true),
      500
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.value]);

  const handleChangePage = (event: any, newPage: any) => {
    const finalPage = newPage > 0 ? page + 1 : page - 1;
    setQueryStartEnd([
      ((finalPage + 1) * rowsPerPage - rowsPerPage).toString(),
      rowsPerPage.toString(),
    ]);
    setPage(finalPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    const finalRowsPerPage = parseInt(event.target.value);
    setQueryStartEnd([
      ((page + 1) * finalRowsPerPage - finalRowsPerPage).toString(),
      finalRowsPerPage.toString(),
    ]);
    setRowsPerPage(finalRowsPerPage);
    setPage(0);
  };

  function buildQR(id: any) {
    setIdClient(id);
    setModalOpen(true);
  }

  function callWhatsapp(number: any) {
    const url = "https://api.whatsapp.com/send?phone=55" + number;
    window.open(url, "_blank");
  }

  return (
    <Page title="Clientes | Optools">
      <Container>
        <ClientModal
          open={modalOpen}
          idClient={idClient}
          title="Número de páginas"
          confirmButtonText="Concluir"
          handler={() => setModalOpen(false)}
        />
        {prePaid && (
          <ReportModal
            prePaid={prePaid}
            open={reportModal}
            idClient={idClient}
            title="Número de páginas"
            confirmButtonText="Concluir"
            handler={() => setReportModal(false)}
          />
        )}

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h4" gutterBottom>
            Clientes
          </Typography>
        </Stack>

        <Stack direction={["column", "row"]} justifyContent="space-between">
          <ClientsListToolbar
            prepaid={params.prepaid}
            filterName={params.value}
            situation={params.situation}
            customerType={user.role !== "Vendedor"}
            onFilterName={(event: any) => {
              setPage(0);
              setQueryStartEnd(["0", rowsPerPage.toString()]);
              setParams({ ...params, value: event.target.value });
            }}
            onPrepaidChange={(value: number) => {
              setPage(0);
              setQueryStartEnd(["0", rowsPerPage.toString()]);
              setParams({
                ...params,
                prepaid: value ? (value === 1 ? true : false) : undefined,
              });
            }}
            onSituationChange={(value: number) => {
              setPage(0);
              setQueryStartEnd(["0", rowsPerPage.toString()]);
              setParams({
                ...params,
                situation: value ? (value === 1 ? true : false) : undefined,
              });
            }}
          />

          <Button
            sx={{
              height: "48px",
              borderRadius: "40px",
              background: "#004A78",
              marginTop: ["12px", "26px"],
              marginBottom: ["24px", "0"],
              "&:hover": {
                bgcolor: "#226C9A",
              },
            }}
            color="secondary"
            variant="contained"
            component={RouterLink}
            to="/dashboard/clientes/cadastrar"
            startIcon={<Icon icon={plusFill} />}
          >
            Cadastrar novo
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ClientsListHead headLabel={tableHead} />
                <TableBody>
                  {data.length ? (
                    data.slice(0, rowsPerPage).map((row: any) => {
                      const {
                        lastDate,
                        id_Customer,
                        name_Company,
                        name_Customer,
                        prepaid_Customer,
                        whatsapp_Customer,
                        initials_Customer,
                        sellerName_Customer,
                        pictureFilepath_User,
                        email_User,
                        completeRegistration_Customer,
                      } = row;

                      return (
                        <TableRow
                          hover
                          key={id_Customer}
                          tabIndex={-1}
                          role="checkbox"
                        >
                          <TableCell component="th" scope="row" padding="none">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={3}
                              ml={2}
                            >
                              <Avatar
                                alt={name_Customer}
                                src={pictureFilepath_User}
                              />
                              <Typography
                                variant="subtitle2"
                                noWrap
                                sx={{
                                  color: name_Customer ? "#004A78" : "#B0B0B0",
                                  fontSize: "14px",
                                }}
                              >
                                {name_Customer || "Não Cadastrado"}
                              </Typography>
                            </Stack>
                          </TableCell>
                          {user?.role !== "Vendedor" ? (
                            <>
                              <TableCell align="left">
                                <Typography
                                  variant="subtitle2"
                                  noWrap
                                  sx={{ color: "#004A78", fontSize: "14px" }}
                                >
                                  {initials_Customer}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                <Typography
                                  variant="subtitle2"
                                  noWrap
                                  sx={{
                                    color: "#A098AE",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                  }}
                                >
                                  {lastDate || "Nenhuma OS"}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                <Typography
                                  variant="subtitle2"
                                  noWrap
                                  sx={{
                                    color: "#004A78",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                  }}
                                >
                                  {sellerName_Customer || "Não Cadastrado"}
                                </Typography>
                              </TableCell>
                            </>
                          ) : undefined}
                          <TableCell align="left">
                            <Typography
                              variant="subtitle2"
                              noWrap
                              sx={{
                                color: "#004A78",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                            >
                              {name_Company}
                            </Typography>
                          </TableCell>

                          <TableCell align="left">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="secondary"
                                  disableTouchRipple
                                  sx={{ p: 0, pr: "4px" }}
                                  checked={
                                    completeRegistration_Customer ? true : false
                                  }
                                  inputProps={{
                                    "aria-label": "primary checkbox",
                                  }}
                                />
                              }
                              label={
                                completeRegistration_Customer ? "SIM" : "NÃO"
                              }
                              sx={{ marginLeft: 0.5 }}
                            />
                          </TableCell>

                          <TableCell>
                            <Button
                              sx={{ marginLeft: -0.5 }}
                              onClick={() => callWhatsapp(whatsapp_Customer)}
                            >
                              <WhatsAppIcon color="primary" />
                            </Button>
                          </TableCell>
                          {user?.role !== "Vendedor" ? (
                            <>
                              <TableCell>
                                <Button
                                  onClick={() => buildQR(id_Customer)}
                                  color="inherit"
                                >
                                  <Icon width="24px" icon={QRCodeIcon} />
                                </Button>
                              </TableCell>
                              <TableCell>
                                <Button
                                  onClick={() => {
                                    console.log(prepaid_Customer);
                                    if (prepaid_Customer === 0) {
                                      navigate(
                                        "/dashboard/relatorio-admin?id=" +
                                          initials_Customer
                                      );
                                    }
                                    setPrePaid(prepaid_Customer === 1);
                                    setIdClient(id_Customer);
                                    setReportModal(true);
                                  }}
                                  color="secondary"
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    ABRIR
                                  </Typography>
                                </Button>
                              </TableCell>
                            </>
                          ) : undefined}
                          <TableCell align="left">
                            <ClientOptions
                              id={id_Customer}
                              email={email_User}
                              whatsapp={whatsapp_Customer}
                              updateData={updateData}
                              sellerUser={user.role === "Vendedor"}
                              disabled={!!completeRegistration_Customer}
                              setUpdateData={(res: any) => setUpdateData(res)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                        <Stack
                          width="100%"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Icon
                            icon={inboxOutline}
                            width="56px"
                            color="#909090"
                          />
                          <Typography
                            mt={1}
                            variant="body1"
                            noWrap
                            sx={{ color: "#909090" }}
                          >
                            Nenhum item encontrado...
                          </Typography>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            page={0}
            component="div"
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[20, 40, 60]}
            labelRowsPerPage="Linhas por página:"
            backIconButtonProps={{ disabled: !page }}
            nextIconButtonProps={{
              disabled:
                (page + 1) * rowsPerPage >= data[0]?.total_Customer ||
                data.length < rowsPerPage,
            }}
            labelDisplayedRows={(rowsInfo) => {
              const totalPages = Math.ceil(
                rowsInfo.count / rowsPerPage
              ).toString();
              return (
                <>
                  {`Página: ${page + 1}${
                    !params.value.length && !params.prepaid && !params.situation
                      ? `/${totalPages === "0" ? "1" : totalPages}`
                      : ""
                  }`}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {!params.value.length && !params.prepaid && !params.situation
                    ? `Total: ${rowsInfo.count}`
                    : ""}
                </>
              );
            }}
            onRowsPerPageChange={handleChangeRowsPerPage}
            count={data?.length ? data[0].total_Customer : 0}
          />
        </Card>
      </Container>
    </Page>
  );
}
